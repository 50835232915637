import {base_url, tenls_url, tenls_region, ux_plugin_url, env, regionToTenLSMapping, signup_environment, TENLS_URL_FORMAT} from '../config/apiConfig';
import * as _ from 'lodash';
// This allows mocking in unit tests
import * as thisModule from "./FetchService";


export const fetchFeatureFlags = () => {
    if (base_url === '') {
        return Promise.resolve({ status: 404, json: () => Promise.resolve() })
    }
    return fetch(`${base_url}/featureFlags`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
};

export const fetchTenlsFeatureFlags = (token) => {
    if (tenls_url === '') {
        return Promise.resolve({ status: 404, json: () => Promise.resolve() })
    }
    return fetch(`${_.replace(tenls_url, '%s', tenls_region)}/featureFlags?token=${token}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
};

export const fetchOrders = (token, call2Tenls) => {
    const fetchURL = `${call2Tenls ? _.replace(tenls_url, '%s', tenls_region) : base_url}/orders?token=${token}`;
    return fetch(
        fetchURL,
        {
          method: 'GET',
        headers: {'Content-Type': 'application/json'}
        }
    );
};

export const fetchAccountNameValidation = (nameAttempt, call2tenls = false, domainType = {}, validationURL) => {
    //Get JWT toke from url query
    const token = new URLSearchParams(window.location.search).get('activationToken');
    const decoded = thisModule.jwt2Obj(token);
    const lHeaders = _.get(decoded, 'version', '') === 'V2' && call2tenls === false
        ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
        : {'Content-Type': 'application/json'};
    const tenlsURL = validationURL !== '' ? validationURL : _.replace(tenls_url, '%s', tenls_region);
    const fetchURL = call2tenls ? `${tenlsURL}/accounts?token=${token}&cloudAccount=${nameAttempt}` : `${base_url}/validateAccountName`;
    const body = domainType && domainType.value && domainType.value === 'LightWeightDomain' ? JSON.stringify({ name: nameAttempt, domainType: domainType.value, token: token }) : JSON.stringify({name: nameAttempt, token: token});
    return fetch(
        fetchURL,
        {
            method: call2tenls ? 'GET':'POST',
            headers: lHeaders,
            body: call2tenls ? null : body
        }
    );
};

export const fetchBanner = () => {
  return fetch(
    `${base_url}/banner`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    }
  );
};

export const fetchRegions = (path, call2tenls, publicOffer = false) => {
    const clientIdParam = path.includes('?') && path.includes('=') ? `&clientId=ActivateUI` : '?clientId=ActivateUI';
    const token = new URLSearchParams(window.location.search).get('activationToken');
    const envParam = publicOffer && ['uat', 'uat2'].includes(env) ? `&environment=${signup_environment.toLocaleLowerCase()}`: '';
    const fetchURL = `${call2tenls ? envParam === '' ? _.replace(tenls_url, '%s', tenls_region) : _.replace(TENLS_URL_FORMAT, '%s', 'us-ashburn-1.oci.oraclecloud.com') : base_url}${path}${clientIdParam}${envParam}${'&token=' + token}`;
    return fetch(
        fetchURL,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
    });
};

export const fetchPutCreateTenancy = (token, id, homeRegion) => {
    const fetchURL = `${_.replace(tenls_url, '%s', tenls_region)}/orders?token=${token}`;
    const body = JSON.stringify({id, homeRegion, token});
    return fetch(
        fetchURL,
    {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: body
    });
}

export const fetchCreateTenancy = (token, body, call2tenls, homeRegion, publicOffer = false) => {
    const decoded = thisModule.jwt2Obj(token);
    const version = _.get(decoded, 'version', '');
    const lHeaders = version === 'V2' && call2tenls === false
                ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
                : {'Content-Type': 'application/json'};
    const envParam = publicOffer && ['uat', 'uat2'].includes(env) ? `environment=${signup_environment.toLocaleLowerCase()}&`: '';
    const fetchURL = `${call2tenls ? getTenLSEndpoint(homeRegion) : base_url}/tenancies?${envParam}token=${token}`

  return fetch(
    fetchURL,
    {
      method: 'POST',
      headers: lHeaders,
      body: body
    }
  );
};

export const fetchAsset = (path) => {
    return fetch(
        `${ux_plugin_url}${path}`,
        { method: 'GET' }
    );
};

export const fetchStateId = (country) => fetch(
    `${base_url}/states?countryId=${country}`,
    {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    },
);

export const jwt2Obj = (token) => {
    let result;
    try {
        result = JSON.parse(Buffer.from(_.split(token, '.')[1], 'base64').toString());
    } catch {
        result = null;
    }
    return result;
};

export const getFeatureFlag = (featureName, featureFlags) => {
    if (!_.isEmpty(featureFlags)) {
        const index = _.findIndex(featureFlags, (o) => o.featureName === featureName);
        return index >= 0 ? featureFlags[index].isActive : false;
    }

    return false;
};

export const getTenLSEndpoint = (homeRegion) => {
    if (['dev', 'uat', 'uat2', 'prd'].includes(env)) {
        const endpoint = regionToTenLSMapping.has(homeRegion) ? regionToTenLSMapping.get(homeRegion) : _.replace(tenls_url, '%s', homeRegion);
        return endpoint;
    }
    return _.replace(tenls_url, '%s', homeRegion);
}

export const isInAllowedListedTokens = (token) => {
    const list = [];
    return list.includes(token);
}

